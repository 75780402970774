.Award_Container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}

.Award_Container img {
    height: 40px;  
    margin-bottom: 10px;
}

.Award_Container li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    background: #F5F8FA;
    width: 350px;
    height: 250px;
}

.Award_Br_Mob {
  display: none;
}

.Award_Container span {
    font-family: SUIT-Thin;
}

.slide-in-fwd-bottom1 {
	-webkit-animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-bottom2 {
	-webkit-animation: slide-in-fwd-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-bottom3 {
	-webkit-animation: slide-in-fwd-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-fwd-bottom4 {
	-webkit-animation: slide-in-fwd-bottom 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-bottom 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(300px);
              transform: translateZ(-1400px) translateY(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
}

  @keyframes slide-in-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(300px);
              transform: translateZ(-1400px) translateY(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
}



@media screen and (max-width: 1480px) {
  .Award_Container li {
    width: 300px;
    height: 200px;
  }
}

@media screen and (max-width: 1280px) {
  .Award_Container li {
    font-size: 17px;
    width: 250px;
    height: 200px;
  }

  .Award_Container img {
    height: 30px;  
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1080px) {
  
  .Award_Container {
    grid-template-columns: repeat(2,1fr);
  }

}

@media screen and (max-width: 770px) {
  .Award_Container {
    grid-template-columns: repeat(1,1fr);
    width: 700px;
  }

  .Award_Container li {
    font-size: 17px;
    width: 90%;
    height: 130px;
    margin: 0 auto;
  }

  .Award_Container img {
    height: 30px;  
    margin-bottom: 5px;
  }

  .Award_Br {
    display: none;
  }
}

@media screen and (max-width:420px) {
  .Award_Container li {
    font-size: 12px;
    width: 85%;
    height: 120px;
    margin: 0 auto;
    line-height: 20px;
  }

  .Award_Container img {
    height: 25px;
    margin-bottom: 0px;
  }

  .Award_Br_Mob {
    display: block;
  }
  
}