.About_Intro_Box {
    padding-top: 250px;
    background: #F5F8FA;
}

.About_Intro_Box p {
    font-size: 45px;
    font-family: SUIT-SemiBold;   
    line-height: 90px;
}

.About_Br {
    display: none;
}

.About_Img_Tab {
    display: none;
}

.About_Intro_Box span {
    font-size: 19px;
    line-height: 40px;
}

.About_Intro_Img {
    display: flex;
    justify-content: center;
    padding: 100px 0 150px 0;
}

.About_Intro_Img img {
    height: 550px;
}

.About_Plan_Box {
    display: flex;
    justify-content: center;
}


.About_Award_Box {
    display: flex;
    justify-content: center;
    margin: 200px 0 100px 0;
}

.About_Partner_Box {
    padding-top: 150px;
    margin-bottom: 200px;
    width: 100%;
}

.About_Partner_Box > div:nth-child(1) h1 {
    font-size: 40px;
    margin-bottom: 15px;
}

.About_Partner_Box > div:nth-child(1) > span {
    font-size: 17px;
    color: #848484;
}

.About_Member_Box {
    padding: 200px 0;
    background: rgb(245, 248, 250);    
}

.About_Member_Box > h1 { 
    font-size: 40px;
}


@media screen and (max-width: 1280px) {

    .About_Intro_Box p {
        font-size: 37px;   
    }

    .About_Intro_Box span {
        font-size: 15px;
        line-height: 0px;
    }

    .About_Intro_Img img {
        height: 450px;
    }

}

@media screen and (max-width: 1080px) {
    .About_Award_Box {
        margin: 100px 0 100px 0;
    }
}

@media screen and (max-width: 980px) {

    .About_Intro_Box {
        padding-top: 300px
    }

    .About_Img_Desk {
        display: none;
    }

    .About_Intro_Img img {
        height: 550px;
    }

    .About_Img_Tab {
        display: block;
        width: 650px;
    }
}



@media screen and (max-width: 770px) {
    .About_Br {
        display: block;
    }

    .About_Br_De {
        display: none;
    }

    .About_Intro_Box {
        padding-top: 150px;
    }

    .About_Intro_Box p {
        line-height: 50px;
        margin-bottom: 20px;
    }

    .About_Partner_Box > div:nth-child(1) h1 {
        font-size: 35px;
    }

    .About_Partner_Box > div:nth-child(1) > span {
        font-size: 15px;
    }    

    .About_Member_Box > h1 {
        font-size: 35px;
    }

    .About_Intro_Img img {
        width: 470px;
        height: 420px;
    }

}

@media screen and (max-width: 680px) {

    .About_Partner_Box > div:nth-child(1) h1 {
        font-size: 25px;
    }

    .About_Member_Box > h1 {
        font-size: 25px;
    }


}

@media screen and (max-width: 580px) {
    .About_Intro_Box span {
        font-size: 12px;
    }

    
}

@media screen and (max-width:500px) {

    .About_Intro_Box p {
        font-size: 25px;
        line-height: 40px;
    }
    
}

@media screen and (max-width: 420px) {
    .About_Intro_Box {
        padding-top: 115px;
    }

    .About_Intro_Img img {
        width: 340px;
        height: 310px;
    }

    .About_Intro_Img {
        display: flex;
        justify-content: center;
        padding: 50px 0;
    }

    .About_Award_Box {
        margin: 100px 0 50px 0;
    }

    .About_Partner_Box {
        padding-top: 50px;
    }

    .About_Partner_Box {
        margin-bottom: 100px;
    }

    .About_Member_Box {
        padding: 100px 0;
    }
}