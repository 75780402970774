.Home_Intro_Box {
    height: 1000px;
    width: 100%;
    display: inline-block;
    padding-top: 250px;
    background: linear-gradient(109.39deg, #DAFFED 0%, rgba(254, 251, 193, 0.484375) 
    77.54%, rgba(198, 245, 255, 0.48) 100%);
}

.Home_Intro_Box p {
    line-height: 60px;
    font-size: 45px;
    font-family: SUIT-SemiBold;
}

.Home_Br {
    display: none;
}

.Home_Button {
    margin-top: 40px;
    width: 160px;
    height: 70px;
    font-size: 20px;
    background: none;
    border: 2px solid #000;
    border-radius: 100px;
    cursor: pointer;
    transition: all .35s;
    font-family: 'SUIT-SemiBold';
    text-decoration: none;
    color: black;
}

.Home_Button_More {
    margin-top: 70px;
    width: 160px;
    height: 70px;
    font-size: 20px;
    background: none;
    border: 2px solid #000;
    border-radius: 100px;
    cursor: pointer;
    transition: all .35s;
    font-family: 'SUIT-SemiBold';
    text-decoration: none;
    color: black;
}

.Home_Button:hover {
    background-color: #000;
    color: #FFF;
}

.Home_Button_More:hover {
    background-color: #000;
    color: #FFF;
}

.Home_Youtube_Box {
    max-width: 100%;
    display: inline-block;
    width: 85%;
    margin-top: 10vh;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 20%) 10px 10px 20px;
    border: 40px solid rgb(255, 255, 255);
    z-index: 0;
    margin-bottom: 50px;
}

.Home_Youtube {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 15px;
}

.Home_Youtube_Line {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.Home_Infor_Box {
    background-color: rgb(245, 248, 250);
}

.Home_Infor_Box > div:nth-child(1) {
    padding: 180px 0 70px 0;    
}

.Home_Infor_Box div:nth-child(1) h1 {
    font-size: 40px;
}

.Home_Magazine {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Home_Infor_Box > div:nth-child(3)  {
    padding-bottom: 180px;
}

.Home_Company_Box > div:nth-child(1) {
    width: 80%;
    border-top: 1px solid rgb(137, 137, 137);
    margin: 0 auto 180px auto;
}

.Home_Company_Box > div:nth-child(2) h1 {
    font-size: 40px;
    margin-bottom: 15px;
}

.Home_Company_Box > div:nth-child(2) > span {
    font-size: 17px;
    color: #757070;

}

.Home_Company {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home_Partners_Box {
    padding: 70px 0 180px 0;    
    font-size: 23px;
}

.Home_Partners_Box > a {
    padding-bottom: 10px;
    border-bottom: 2px solid #000;
    font-family: SUIT-SemiBold;
    cursor: pointer;
}

.Home_Partners_Box > a > img {
    margin-bottom: -3px;
}

.Home_Partners_Box > a:hover img {
    animation-name: slideright;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes slideright {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(15px);
    }
  }


@media screen and (max-width: 1280px) {
    .Home_Intro_Box p {
        font-size: 37px;
    }
}

@media screen and (max-width: 770px) {
    .Home_Br {
        display: block;
    }

    .Home_Intro_Box {
        padding-top: 150px;
        height: 850px;
    }
    .Home_Intro_Box p {
        line-height: 50px;
    }

    .Home_Infor_Box div:nth-child(1) h1 {
        font-size: 35px;
    }

    .Home_Company_Box > div:nth-child(2) h1 {
        font-size: 35px;
    }

    .Home_Company_Box > div:nth-child(2) > span {
        font-size: 15px;
     
    }

    .Home_Button {
        width: 130px;
        height: 50px;
        font-size: 15px;
    }

    .Home_Button_More {
        width: 130px;
        height: 50px;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .Home_Br {
        display: block;
    }

    .Home_Intro_Box {
        height: 710px;
    }

    .Home_Intro_Box p {
        line-height: 40px;
        font-size: 25px;
    }

    .Home_Infor_Box div:nth-child(1) h1 {
        font-size: 25px;
    }

    .Home_Youtube_Box {
        border: 20px solid rgb(255, 255, 255);
    }

    .Home_Company_Box > div:nth-child(2) h1 {
        font-size: 25px;
    }

    .Home_Partners_Box {
        font-size: 20px;
        padding: 50px 0 180px 0;
    }

    .Home_Button {  
        width: 120px;
        height: 50px;
        font-size: 17px;
    }
}

@media screen and (max-width: 420px) { 
    .Home_Youtube_Box {
        box-shadow: rgb(0 0 0 / 13%) 10px 10px 20px;
        border: 15px solid rgb(255, 255, 255);
        border-radius: 15px;
    }

    .Home_Youtube {
        border-radius: 10px;
    }
    
    .Home_Intro_Box {
        padding-top: 125px;
        height: 585px;
    }

    .Home_Partners_Box > a > img {
        margin-bottom: -5px;
        width: 24px;
        height: 24px;
    }

    .Home_Infor_Box > div:nth-child(1) {
        padding: 120px 0 70px 0;
    }

    .Home_Infor_Box > div:nth-child(3) {
        padding-bottom: 120px;
    }

    .Home_Company_Box > div:nth-child(1) {
        margin: 0 auto 120px auto;
    }

    .Home_Partners_Box {
        padding: 50px 0 120px 0;
    }


}