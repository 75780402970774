* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: SUIT-Regular;
  list-style: none;
}

.Link_CSS {
  text-decoration: none;
  color: black;
}

.App {
  text-align: center;
}

.font {
  font-family: SUIT-SemiBold;
}