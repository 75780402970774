.Member_Container {
    margin: 50px 0;
}

.Member_Container h3 {
    font-size: 20px;
    line-height: 45px;
}

.Member_Container h3 span {
    color: #EC5B44;
}

.Member_Img {
    width: 70%;
    height: 100%;

}

.Member_Br {
    display: none
}

@media screen and (max-width: 770px) {
    .Member_Container h3 {
        font-size: 20px;
        line-height: 35px;
    }

    .Member_Br {
        display: block
    }
}

@media screen and (max-width: 500px) {
    .Member_Container h3 {
        font-size: 17px;
        line-height: 30px;
    }
}

@media screen and (max-width: 420px) {
    .Member_Container h3 {
        font-size: 15px;
        line-height: 25px;
    }
}