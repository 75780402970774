.Magazine_Container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 45px 0;
    place-items: center;
}

.Magazine_Container li {
    border-bottom: 1px solid #000;
    width: 85%;
}

.Magazine_Br {
    display: none;
}

.Magazine_Br_Mob {
    display: none;
}

.Magazine_Box div {
    text-align: left;
    margin-bottom: 10px;
}

.Magazine_Box div span {
    color: rgb(102, 102, 102);
    font-size: 15px;
}

.Magazine_Box_Svg {
    filter: invert(47%) sepia(77%) saturate(1446%) hue-rotate(330deg) brightness(92%) contrast(101%);
}

.Magazine_Box div img {
    width: 14px;
    height: 14px;
}

.Magazine_Box a:hover img {
    animation-name: slideright;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes slideright {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(15px);
    }
  }
  

.Magazine_Box p {
    font-size: 19px;
    text-align: left;
    line-height: 35px;
    height: 115px;
}

@media screen and (max-width: 1500px) {
    .Magazine_Container {
        gap: 45px 5px;
    }
}


@media screen and (max-width: 1280px) {
    .Magazine_Box p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1250px) {
    .Magazine_Box p {
        font-size: 17px;
    }
}

@media screen and (max-width: 770px) {

    .Magazine_Box p {
        font-size: 15px;
        line-height: 27px;
    }

    .Magazine_Box div {
        display: flex;
        align-items: center;
    }
    
    .Magazine_Container {
        overflow-y: hidden;
        overflow-x: auto;
        height: 170px;
        width: 80%;
        display: -webkit-inline-box;
    }

    .Magazine_Br_def {
        display: none;
    }

    .Magazine_Br {
        display: block;
    }

    .Magazine_Container::-webkit-scrollbar {
        width: 100%;
        height: 4px
      }

      .Magazine_Container::-webkit-scrollbar-thumb {
        background-color: #2f3542;
      }
      .Magazine_Container::-webkit-scrollbar-track {
        background-color: rgb(227, 227, 227);
      }

    .Magazine_Container li { 
        border-bottom: none;
        width: 45%;
    }

    
}


@media screen and (max-width: 420px) { 

    .Magazine_Box div span {
        font-size: 10px;
    }

    .Magazine_Box p {
        font-size: 12px;
        line-height: 20px;
    }

    .Magazine_Box div img {
        width: 10px;
        height: 10px;
    }

    .Magazine_Br_Mob {
        display: block;
    }

    .Magazine_Container {
        height: 140px;
    }
}